// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'staging-na',
  production: false,
  region: 'NA',
  appUrl: 'https://your_staging_endpoint/',

  apiBase: {
    url: 'https://api.zonarsystems.net/gtcx-trip-api/v1/trip-zone'
  },

  coreEntityApiBase: {
    url: 'https://api.zonarsystems.net'
  },

  userPreferencesApiBase: {
    url: 'https://api.zonarsystems.net/alert/v1'
  },

  // TODO: Setup your application's authentication variables: see
  // https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth
  // for instructions on how to do that.
  authEnv: 'production',

  auth: {
    clientID: 'yZ1GDYUiIZSZjxyp9mep3Uw8nnOjxt05',
    domain: 'zonar-login.auth0.com',
    audience: 'https://api.zonarsystems.net',
    // These come from the Core APIs as your application is built.
    applicationId: 'cae8660c-fcd5-4d36-95f7-090d299dc5a7',
    defaultZonarRole: 'f3840d2c-9273-463b-ad79-c01a63a0cbb4',
    useRefreshTokens: true
  },

  pendo: {
    url: 'https://cdn.pendo.io/agent/static/'
  },

  devCycle: {
    clientId: 'dvc_client_4b58603a_4db1_467d_81fd_dd4836c44051_bdc649f'
  },

  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: '',
    environment: ''
  },

  //TODO: Setup your application's datadog app (see empty box readme)
  datadog: {
    applicationId: 'a9c074c5-b9e7-4c78-9ec5-9380eeaeba5a',
    clientToken: 'pub5725211aeea4a4f42e976e6b76562c75',
    site: 'us5.datadoghq.com',
    tagEnvironment: 'staging'
  },

  i18n: {
    supportedLanguages: [
      'en-US',
      'de-DE',
      'en-GB',
      'fr-FR',
      'es-ES',
      'it-IT',
      'en-CA'
    ],
    defaultLanguage: 'en-US'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
